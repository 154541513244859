import React from 'react'
import LandingPage from '../Components/LandingPage.jsx'
import HomeSecondSection from '../Components/HomeSecondSection.jsx'
import HomeThirdSection from '../Components/HomeThirdSection.jsx'
import WhyUs from '../Components/WhyUs.jsx'
import FeedBack from '../Components/FeedBack.jsx'
import Footer from '../Components/Footer.jsx'

function Home() {
  return (
    <div className='home-page-container'>
      <LandingPage/>
      <HomeSecondSection/>
      <HomeThirdSection/>
      <WhyUs/>
      <FeedBack/>
      <Footer/>
        
    </div>
  )
}

export default Home