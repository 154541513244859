import React from 'react'
import { Link } from 'react-router-dom'
import CityBk from '../Assets/city-background2.jpg'
import RightArrow from '../Assets/angle-up.svg'
import '../Styles/landingPage.css'
import FeedBack from './FeedBack'

function LandingPage() {
  return (
    <div className='landing-page-container'>
        <div className="landing-background">
            <img src={CityBk} alt="city-background" />
        </div>
        <div className="landing-background-text">
            <h4>Welcome to EHL</h4>
            <h1>When It Comes To Reliability, We Are The One You Need!</h1>
            <p> Start your journey to exceptional delivery services and support. We ensure our cutomers' needs are met with professionalism and care.
            </p>
            
            <button> 
            <Link to='/About'>Know More</Link>
            <img className='right-arrow' src={RightArrow} alt="right-angle-arrow" />
          </button>
            
     
        </div>
      
        <div className="feedback-main-container">
            <FeedBack/>
            
        </div>
        
    </div>
  )
}

export default LandingPage