import React from 'react'
import ServiceHeroSection from '../Components/ServiceHeroSection '
import '../Styles/serviceHeroSection.css'
import HomeThirdSection from '../Components/HomeThirdSection'
import FeedBack from '../Components/FeedBack'
import Footer from '../Components/Footer'
import HomeSecondSection from '../Components/HomeSecondSection'

function Services() {
  return (
    <div>
      <ServiceHeroSection/>
      <HomeThirdSection/>
      <HomeSecondSection/>
      <FeedBack/>
      <Footer/>

    </div>
  )
}

export default Services