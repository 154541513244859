import React from 'react'
import AboutHeroSection from '../Components/AboutHeroSection'
import HomeSecondSection from '../Components/HomeSecondSection'
import Principle from '../Components/Principle'
import FeedBack from '../Components/FeedBack'
import Footer from '../Components/Footer'

function About() {
  return (
    <div className='about-main-container'> 
    <AboutHeroSection/>
    <HomeSecondSection/>
    <Principle/>
    <FeedBack/>
    <Footer/>      
    </div>
  )
}

export default About