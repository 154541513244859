import React, { useState } from 'react'
import '../Styles/principle.css'

function Principle() {

    const [active, setActive] = useState(1)

  
    console.log(active);
    
  return (
    <div className='principle-container'>

        <div className="principle-tabs">
            <div onClick={()=> setActive(1)} className={active === 1? 'tab-1 active-tab': 'tab-1'}>Vision</div>
            <div onClick={()=> setActive(2)} className={active === 2? 'tab-2 active-tab': 'tab-2'}>Mission</div>
            <div onClick={()=> setActive(3)} className={active === 3? 'tab-3 active-tab': 'tab-3'}>Value</div>
        </div>

        <div className="principle-texts">
           {active === 1 &&  <div className="vision">
                <p>Our vision is to be the trusted partner for individuals and organizations seeking seamless solutions, exceptional service, and unparalleled expertise.</p>
            </div>}
           {active === 2 &&  <div className="mission">
                <p>Our mission is to empower individuals, businesses, and communities by providing comprehensive integrated services, including property management, recruitment, travel, education finance, relocation support, and expert training and consulting, to foster growth, exceptional experiences, and unparalleled success</p>
            </div>}
           { active === 3 && <div className="value">
                <h5>Our core values are:</h5>
                <ul>
                    <li>Integrity: Transparency, reliability, and ethics guide every decision.</li>
                    <li>Innovation: Embracing cutting-edge solutions to drive progress.</li>
                    <li>Empathy: Understanding unique needs and delivering tailored support.</li>
                    <li>Excellence: Striving for perfection in every interaction.</li>
                </ul>
            </div>}
        </div>

    </div>
  )
}

export default Principle