import React from 'react'
import ServiceBk from '../Assets/service-image.jpg'
import '../Styles/aboutHeroSection.css'

function ServiceHeroSection() {
  return (
    <div className='service-hero-section-container'>
        <div className="service-hero-background">
            <img src={ServiceBk} alt="service-background" />
        </div>
        <div className="service-background-text">
            <h2>Our Services</h2>
            <p>At EHL, we offer a comprehensive range of services designed to empower individuals, businesses, and communities.</p>
        </div>
        
    </div>
  )
}

export default ServiceHeroSection