import React from 'react'
import ContactBk from '../Assets/contact.jpg'
import '../Styles/contactHeroSection.css'

function ContactHeroSection() {
  return (
    <div className='contact-hero-section-container'>
        <div className="contact-hero-background">
            <img src={ContactBk} alt="contact-background" />
        </div>
        <div className="contact-background-text">
            <h2>Get In Touch With Us</h2>
            <p>We're here to help. Reach out to us with questions, feedback, or to explore how our services can support your goals</p>
        </div>
        
    </div>
  )
}

export default ContactHeroSection