import React from 'react'
import { Link } from 'react-router-dom'
import HostBk from '../Assets/hospitality.jpg'
import RecruitmentBk from '../Assets/recruitment.jpg'
import TravelBk from '../Assets/training.jpg'
import SchoolLoanBk from '../Assets/school-placement.jpg'
import TrainingBk from '../Assets/training.jpg'
import RightArrow from '../Assets/angle-up.svg'
import '../Styles/homeThirdSection.css'

function HomeThirdSection() {
  return (
    <div className='home-third-section-container'>
        <div className="home-third-section-text">
            <h2>What We Do</h2>
            <p>We are dedicated to rendering quality services</p>
        </div>

        <div className="home-third-section-cards">
        <div className="hospitality">
            <div className="hospitality-image">
                <img src={HostBk} alt="hospitality-background" />
            </div>
            <div className="hospitality-text">
                <h3>Facility and Hospitality Management</h3>
                <p>Expert management of facilities and hospitality services to ensure seamless operations.
                </p>
                <button> 
                    <Link to='/Contact'>Get in Touch</Link>
                     <img className='right-arrow' src={RightArrow} alt="right-angle-arrow" />
                </button>
            </div>
        </div>
        <div className="recruitment">
            <div className="recruitment-image">
                <img src={RecruitmentBk} alt="recruitement-background" />
            </div>
            <div className="recruitment-text">
                <h3>Recruitment</h3>
                <p>Comprehensive recruitment services to find the best talent for your organization.
                </p>
                <button> 
                    <Link to='/Contact'>Get in Touch</Link>
                     <img className='right-arrow' src={RightArrow} alt="right-angle-arrow" />
                </button>
            </div>
        </div>
        <div className="travel">
            <div className="travel-image">
                <img src={TravelBk} alt="travel-background" />
            </div>
            <div className="travel-text">
                <h3>Travels and Vacation Packages</h3>
                <p>Customized travel and vacation packages to make your trips memorable.
                </p>
                <button> 
                    <Link to='/Contact'>Get in Touch</Link>
                     <img className='right-arrow' src={RightArrow} alt="right-angle-arrow" />
                </button>
            </div>
        </div>
        <div className="loan">
            <div className="loan-image">
                <img src={SchoolLoanBk} alt="school-loan-background" />
            </div>
            <div className="loan-text">
                <h3>
                Loan to School, Education, and Relocation Advisory</h3>
                <p>Financial support and advisory services for education and relocation.

                </p>
                <button> 
                    <Link to='/Contact'>Get in Touch</Link>
                     <img className='right-arrow' src={RightArrow} alt="right-angle-arrow" />
                </button>
            </div>
        </div>
        <div className="training">
            <div className="training-image">
                <img src={TrainingBk} alt="school-training-background" />
            </div>
            <div className="training-text">
                <h3>Training and Consulting: </h3>
                <p>Tailored training programs and consulting services to enhance your team's skills and knowledge.</p>
                <button> 
                    <Link to='/Contact'>Get in Touch</Link>
                     <img className='right-arrow' src={RightArrow} alt="right-angle-arrow" />
                </button>
            </div>
        </div>
        </div>
     
       


    </div>
  )
}

export default HomeThirdSection