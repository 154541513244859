import React from 'react'
import ContactHeroSection from '../Components/ContactHeroSection.jsx'
import FeedBack from '../Components/FeedBack'
import Footer from '../Components/Footer'

function Contact() {
  return (
    <div>
      <ContactHeroSection/>
      <FeedBack/>
      <Footer/>       
    </div>
  )
}

export default Contact