import React from 'react'
import { Link } from 'react-router-dom'
import StoryImage from '../Assets/story-image.jpg'
import RightArrow from '../Assets/angle-up.svg'
import CityBk from '../Assets/city-background1.jpg'
import '../Styles/homeSecondSection.css'



function HomeSecondSection() {
  return (
    <div className='home-second-section-container'>
      <div className="quality-work">
        <div className="quality-work-background">
            <img src={CityBk} alt="city-background" />
        </div>
        <div className="quality-work-text">
          <h2>Quality Work Through Dedication</h2>
          <p>At EHL, we bring nearly a decade of experience across a range of essential services, including hospitality and facility management, professional recruitment, vacation planning, school placements, and visa & relocation advisory. Whether you’re looking for bespoke travel experiences or expert guidance in relocation, EHL is here to help you every step of the way.        
          </p>
        </div>
      
      </div>
      <div className="our-story">
        <div className="story-image">
          <img src={StoryImage} alt="our-story" />
        </div>
        <div className="story-text">
          <h2>Our Story</h2>
          <p>Founded in 2018, EHL is dedicated to providing top-notch services in Facility and Hospitality Management, Recruitment, Travels and Vacation Packages, Loan to School, Education and Relocation Advisory, and Training and Consulting. Our mission is to deliver exceptional service and support to our clients, ensuring their needs are met with professionalism and care.
          </p>

          <button> 
            <Link to='/Contact'>Know More</Link>
            <img className='right-arrow' src={RightArrow} alt="right-angle-arrow" />
          </button>
        </div>
      </div>
      
    </div>
  )
}

export default HomeSecondSection