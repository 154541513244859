import React from 'react'
import AboutBk from '../Assets/about-us.jpg'
import '../Styles/aboutHeroSection.css'

function AboutHeroSection() {
  return (
    <div className='about-hero-section-container'>
        <div className="about-hero-background">
            <img src={AboutBk} alt="about-background" />
        </div>
        <div className="about-background-text">
            <h2>About Us</h2>
            <p>At EHL, we're a dynamic and multifaceted organization dedicated to providing comprehensive solutions across various sectors.</p>
        </div>
        
    </div>
  )
}

export default AboutHeroSection