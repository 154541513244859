import React from 'react'
import FaqHeroSection from '../Components/FaqHeroSection'
import FaqSecondSection from '../Components/FaqSecondSection'
import FeedBack from '../Components/FeedBack'
import Footer from '../Components/Footer'

function Faq() {
  return (
    <div className='faq-main-container'>
      <FaqHeroSection/>
      <FaqSecondSection/>
      <FeedBack/>
      <Footer/>
    </div>
  )
}

export default Faq