import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home.jsx'
import Services from './Pages/Services.jsx'
import About from './Pages/About.jsx'
import Faq from './Pages/Faq.jsx'
import Contact from './Pages/Contact.jsx'
import './Styles/app.css'
import Nav from './Components/Nav.jsx'
import ScrollToTop from './Components/ScrollToTop.jsx';

function App() {
  return (
    <div className="App">
      <Nav/>
      <ScrollToTop/>

      <Routes>
       <Route path='/' element={<Home/>}/>
       <Route path='/About' element={<About/>}/>
       <Route path='/Services' element={<Services/>}/>
       <Route path='/Faq' element={<Faq/>}/>
       <Route path='/Contact' element={<Contact/>}/>
      </Routes>
      
      
    </div>
  );
}

export default App;
