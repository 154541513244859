import React from 'react'
import '../Styles/faqSecondSection.css'




function FaqSecondSection() {
  return (
    <div className='faq-second-section'>
           <h2>Facility & Hospitality Management</h2>
        <div className="section-1">         
            <div className="quest-1"> 
                <p className = 'quest'>What types of properties do you manage?</p>
                <p>We manage residential, commercial, and hospitality properties.</p>
            </div>
           
            <div className="quest-2"> 
                <p className = 'quest'>What services do you offer for property maintenance?</p> 
                <p>Our services include housekeeping, concierge support, and repair/maintenance services.</p>
            </div>

            <div className="quest-3">
                 <p className = 'quest'>Can I customize my property management package?</p> 
                 <p>Yes, we offer tailored solutions to meet your specific needs.</p>
            </div>       
        </div>
        <h2>Recruitment</h2>
        <div className="section-2">
         
            <div className="quest-1"> 
                <p className = 'quest'>What industries do you specialize in for recruitment?</p>
                <p>We recruit for various industries, including hospitality, healthcare, finance, and more.</p>
            </div>
            <div className="quest-2"> 
                <p className = 'quest'>What is your recruitment process?</p>
                <p>Our process includes candidate sourcing, screening, interviewing, and placement.</p> 
            </div>
            <div className="quest-3"> 
                <p className = 'quest'>Do you offer temporary or permanent staffing solutions?</p> 
                <p>Yes, we offer both temporary and permanent staffing solutions.</p>
            </div>
        </div>
        <h2>Travels & Vacation Packages</h2>
        <div className="section-3">
        
            <div className="quest-1"> 
                <p className = 'quest'>Can I customize my travel package?</p>
                <p>Yes, we offer tailored travel plans to suit your preferences.</p>
            </div>
            <div className="quest-2"> 
                <p className = 'quest'>What types of vacation packages do you offer?</p>
                <p>We offer packages for individuals, groups, and corporate events.</p>
            </div>
            <div className="quest-3">
                <p className = 'quest'>Do you provide travel insurance?</p>
                <p>Yes, we offer travel insurance options.</p>
            </div>
        </div>
        <h2>Education Finance</h2>
        <div className="section-4">
        
            <div className="quest-1">  
                <p className = 'quest'>What types of education loans do you offer?</p>
                <p>We offer student loans, education grants, and financial aid advisory services.</p>        
            </div>

            <div className="quest-2"> 
                <p className = 'quest'>What is the eligibility criteria for education loans?</p>
                <p>Eligibility criteria vary; contact us for more information.</p>
            </div>
            <div className="quest-3">
                <p className = 'quest'>Do you offer flexible repayment plans?</p>
                <p>Yes, we offer flexible repayment plans.</p>
            </div>
        </div>
        <h2>Training & Consulting</h2>

        <div className="section-5">           
            <div className="quest-1"> 
                <p className = 'quest'> What training programs do you offer?</p>
                <p>We offer professional development, business consulting, and coaching/mentoring services.</p>
            </div>
            <div className="quest-2"> 
                <p className = 'quest'>Can I customize my training program?</p>
                <p>Yes, we offer tailored training solutions.</p>
            </div>
            <div className="quest-3">
                <p className = 'quest'>Do you provide certification upon completion?</p>
                <p>Yes, we offer certification upon completion of select programs.</p>
            </div>
        </div>
    
    </div>
  )
}

export default FaqSecondSection