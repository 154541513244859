import React from 'react'
import FaqBk from '../Assets/faq-image.jpg'
import '../Styles/faqHeroSection.css'

function FaqHeroSection() {
  return (
    <div className='faq-hero-section-container'>
        <div className="faq-hero-background">
            <img src={FaqBk} alt="faq-background" />
        </div>
        <div className="faq-background-text">
            <h2>Frequently Asked Questions</h2>
            <p>Here are some Frequently Asked Questions (FAQs) and Answers.</p>
        </div>
        
    </div>
  )
}

export default FaqHeroSection