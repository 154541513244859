import React, { useState } from 'react'
import '../Styles/feedback.css'
import emailjs from 'emailjs-com';


function FeedBack() {

  const [formInputs, setFormInputs] = useState({
    name: '',
    email: '',
    tel: '',
    subject: '',
    message: ''
  })

  const handleInputs =(e)=> {

    setFormInputs (prev=> ({
      ...prev, 
      [e.target.name]: e.target.value
    }))
  }

  const [isLoading, setIsLoading] = useState(false)

  const sendEmail = (e) => {
      setIsLoading(true);
      e.preventDefault(); // Prevent page refresh
  
      // Create template parameters
      const templateParams = {
      name: formInputs.name,
      email:formInputs.email,
      tel:formInputs.tel,
      subject:formInputs.subject,
      message:formInputs.message,
      };


      // Send email using EmailJS
      emailjs.send('service_b4tvqsm', 'template_x4iut3s', templateParams, 'hMKsvlmpbZfpK2Ajk')
        .then((response) => {
          console.log('Email sent successfully!', response.status, response.text);
          alert('Quote sent successfully!');
        }, (err) => {
          console.error('Failed to send email. Error:', err);
          alert('Failed to send email');
        })
        .finally(() => {
          setIsLoading(false); // End loading after request completes
      });
       
  };

  return (
    <div className='feedback-container'>
      <div className="feedback-text">
        <h2>Request a Quote Today</h2>
        <h3>Reach Us</h3>
        <h4>Location:</h4>
        <p>Conveniently located at 15 Bori Road, Rumuibekwe, Port Harcourt.</p>
        <h4>Email:</h4>
        <p>Contact@earthospitality.com</p>
        <h4>Tel:</h4>
        <p>+234 818 2900 927</p>

      </div>
      <div className="feedback-form">
        <form>
            <input onChange={handleInputs} type="text" name='name' placeholder='Full Name' required/>
            <input onChange={handleInputs} type="email" name='email' placeholder='Email'required />
            <input onChange={handleInputs} type="number" name='tel' placeholder='Contact Number'required />
            <input onChange={handleInputs} type="text" name='subject' placeholder='Purpose of Contact'required />
            <textarea onChange={handleInputs}  rows="5" cols="30" name="message"  placeholder='Your Message'></textarea>
            <button onClick={sendEmail} className='submit-btn'> {isLoading ? "Submitting..." : "Get Quote"}</button>
        </form>
      </div>

      

       

    </div>
  )
}

export default FeedBack