import React from 'react'
import '../Styles/whyUs.css'

function WhyUs() {
  return (
    <div className='why-us-container'>
        <div className="why-text">
            <h2>Why Choose Us</h2>
            <p>At EHL, we understand that exceptional hospitality is more than just a service – it's an experience.</p>
        </div>
        <div className="why-us-cards">
            <div className="card-1">
                <h3>Experience:</h3>
                <p>Since 2018, we have been committed to excellence in all our services.
                </p>
            </div>
            <div className="card-2">
                <h3>Expertise:</h3>
                <p>Our team of seasoned professionals ensures high-quality service delivery.
                </p>
            </div>
            <div className="card-3">
                <h3>Customization:</h3>
                <p>We tailor our services to meet the unique needs of each client.
                </p>
            </div>
            <div className="card-4">
                <h3>Reliability:</h3>
                <p>Trust us to deliver consistent and reliable services.
                </p>
            </div>

        </div>
      
    </div>
  )
}

export default WhyUs